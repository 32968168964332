import Row from 'react-bootstrap/Row'
import OfferItemList from "./offer_item_list"
function OfferList({ scrollId, direction, products, sectionRefs, language, currency }) {

    return (
        <>
            <Row className="mx-2" id={`section-${scrollId}`} ref={sectionRefs[scrollId]} dir={direction}>
                {
                    products?.map((item,index) => {
                        return <OfferItemList key={"offerlistitem-"+index} product={item} language={language} currency={currency}/>
                    })
                }
            </Row>
        </>
    );
}

export default OfferList;